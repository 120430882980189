<template>
    <div class="find-picture-container" :style="{'height': 'calc(100vh - ' + headerHeight + 'px)'}">
        <div class="find-content column-center">
            <div class="find-title" >找回照片</div>
            <div class="find-form column-center">
                <input class="find-input" v-model="orderId" placeholder="请输入订单号查询" style="font-size: 16px" />
                <div class="submit-btn row-center" style="font-size: 16px;height: 40px" @click="$findPicture">
                    找回照片
                </div>
            </div>
            <div class="error-text" style="font-size: 14px" v-show="!(errorText == '') && findStatus != 1">
                {{errorText}}
            </div>
            <div class="column" style="margin-top: 48px" v-show="findStatus == 1">
                <div class="primary find-tips" style="font-size: 14px">已查询到订单，照片即将自动下载，也可点击下方按钮下载</div>
                <el-button class="down-load-btn" @click="downloadPicture">
                    下载照片
                </el-button>
            </div>
            <div class="tips row muted">
                <div style="align-self: flex-start;font-size: 14px;">
                    订单号：
                </div>
                <div style="font-size: 14px">
                    微信支付 > 查看订单详情 > 商品<br>
                    支付宝支付 > 查看订单详情 > 商品
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {findPicture, operationLog} from '@API/app';
import {changeDpiDataUrl} from "changedpi"
export default {
    data() {
        return {
            headerHeight: 0,
            orderId: '',
            resultPicture: "",
            findStatus: 0,
            errorText: '',
            spec_width: 0,
            spec_height: 0,
            color: "",
            title: "",
            dpi: 300
        }
    },
    mounted() {
        this.headerHeight = document.getElementsByClassName("my-header-container")[0].clientHeight;
        this.$operationLog({
            btnId: "pc-retrieve photos",
            type: '0'
        })
    },
    methods: {
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        $findPicture() {
            
            if(this.orderId == '') {
                this.$message.error("订单号不能为空")
                return;
            }
            this.$operationLog({
                btnId: "pc-click-retrieve",
                type: '0'
            })
            findPicture({
                id: this.orderId
            }).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        this.findStatus = 1;
                        this.resultPicture = res.obj.uidFolder;
                        let orderInfo = JSON.parse(res.obj.extendJson);
                        this.pxSizeX = orderInfo.pxSizeX;
                        this.pxSizeY = orderInfo.pxSizeY;
                        this.color = orderInfo.bgcolor;
                        this.title = orderInfo.goodsName;
                        this.dpi = orderInfo.dpi
                        this.downloadPicture()
                    }
                    else {
                        this.findStatus = 0
                        this.errorText = "该订单未支付"
                    }
                    // this.downloadPicture()
                }
            }).catch(err => {
                this.findStatus = -1;
                if(err.success == "10005") {
                    this.errorText = "查询不到订单，请输入正确的订单号"
                }
            })
        },
        downloadPicture() {
            let that = this
            let picture_link = document.createElement('a');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.setAttribute("id", "result-picture")
            canvas.width = this.pxSizeX;
            canvas.height = this.pxSizeY;
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.resultPicture;
            image.width = this.pxSizeX;
            image.height = this.pxSizeY;
            ctx.fillStyle = this.color;
            ctx.fillRect(0, 0, this.pxSizeX, this.pxSizeY)
            image.onload = function() {
                // document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, that.dpi)
                picture_link.href = dataURL300DPI;
                picture_link.target = "_blank";
                picture_link.download = that.title + ".jpg";
                picture_link.click()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.find-picture-container {
    background-color: white;
    .find-content {
        padding-top: 112px;
        .find-title {
            color: #111;
            font-weight: 600;
            font-size: 34px;
        }
        .find-form {
            margin-top: 34px;
            .find-input {
                border: 1px solid #E5E5E5;
                padding: 14px;
                width: 250px;
                border-radius: 4px;
                color: #111;
            }
            .submit-btn {
                background-color: $--color-primary;
                color: white;
                height: 40px;
                width: 278px;
                margin-top: 24px;
                cursor: pointer;
            }
        }
        .error-text {
            color: #E65351;
            font-size: 14px;
            margin-top: 34px;
        }
        .tips {
            margin-top: 34px;
            line-height: 25px;
        }
        .down-load-btn {
            color: $--color-primary;
            border: 1px solid $--color-primary;
            margin-top: 48px;
        }
    }
}
</style>